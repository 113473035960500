<template>
  <div>
    <!-- UX: Título, botão para guiar usuário e texto -->
    <titulo-lancamento-historico @cadastrar="openModal" />

    <filtro-historico @resultado="exibirResultado" />

    <tabela-historico
      :items="items"
      @atualizar-tabela="atualizarTabela"
      @editar-historico="editarHistoricoLancamento"
    />

    <modal-historico
      :exibir="modais.historico"
      :form="modais.historicoDados"
      @atualizar-tabela="atualizarTabela"
      @fechar="closeModal"
    />

    <paginacao
      v-model="paginacao"
      :total="paginacao.total"
      @input="atualizarTabela"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import rotas from '@/common/utils/rotas';

// Services:
import HistoricoLancamentoService from '@/common/services/historico-lancamento/historico-lancamento.service.js';

// Components:
import TabelaHistorico from './components/Tabela';
import FiltroHistorico from './components/Filtro';
import ModalHistorico from './components/Modal';
import Paginacao from '@/components/paginacao/Paginacao';
import TituloLancamentoHistorico from '@/views/financeiro/lancamentos/historico/components/TituloLancamentoHistorico';

export default {
  components: {
    TituloLancamentoHistorico,
    FiltroHistorico,
    TabelaHistorico,
    ModalHistorico,
    Paginacao,
  },
  data() {
    return {
      items: [],
      filtroUtilizado: '',
      modais: {
        historico: false,
        historicoDados: {},
      },
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      },
    };
  },
  methods: {
    editarHistoricoLancamento(item) {
      this.openModal('historico');
      this.buscaDadosHistorico(item.id);
    },
    buscaDadosHistorico(id) {
      this.$store.dispatch(START_LOADING);
      HistoricoLancamentoService.obtemHistoricoLancamentoPorId(id)
        .then(({ data }) => {
          this.modais.historicoDados = data;
          this.modais.historicoDados.tipoHistorico = data.tipoHistoricoCodigo;
          this.modais.historicoDados.tipoFluxoFinanceiro =
            data.tipoFluxoFinanceiroCodigo;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    exibirResultado(resultado, filtroUtilizado) {
      this.items = resultado.itens;
      this.paginacao.total = resultado.paginacao.totalDeElementos;
      this.filtroUtilizado = filtroUtilizado;
    },
    atualizarTabela() {
      this.$store.dispatch(START_LOADING);
      HistoricoLancamentoService.obterEnumPaginadoPor(
        this.filtroUtilizado,
        this.paginacao
      )
        .then(({ data }) => {
          this.items = data.itens;
          this.paginacao.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      this.modais[key] = true;
      item
        ? (this.modais[`${key}Dados`] = JSON.parse(JSON.stringify(item)))
        : (this.modais[`${key}Dados`] = {});
    },
    closeModal(key) {
      return (this.modais[key] = false);
    },
    // FUNÇÕES AUXILIARES:
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
