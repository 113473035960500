<template>
  <div class="row">
    <div class="col-12 col-md-4">
      <input-select-search
        v-model="filtro.selecionado"
        label="FINANCEIRO.TIPO_HISTORICO"
        :options="filtro.opcoes.tipoHistorico"
        required
      />
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';
import HistoricoLancamentoService from '@/common/services/historico-lancamento/historico-lancamento.service.js';

// Components:
import { InputSelectSearch } from '@/components/inputs';

export default {
  components: { InputSelectSearch },
  mounted() {
    this.getTipoHistorico();
  },
  data() {
    return {
      filtro: {
        selecionado: '',
        opcoes: {
          tipoHistorico: [],
        },
      },
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      },
    };
  },
  watch: {
    'filtro.selecionado': 'emitirDados',
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getTipoHistorico() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('historico-financeiro')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.filtro.opcoes.tipoHistorico = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES DE EMISSÃO DE DADOS:
    emitirDados(item) {
      this.$store.dispatch(START_LOADING);
      HistoricoLancamentoService.obterEnumPaginadoPor(item, this.paginacao)
        .then(({ data }) => {
          this.$emit('resultado', data, item);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
