<template>
  <div class="row">
    <div class="col-12">
      <input-select-search
        ref="tipoContrato"
        v-model="form.tipoHistorico"
        label="FINANCEIRO.TIPO_HISTORICO"
        :options="opcoes.tipoHistorico"
        required
      />
    </div>
    <div class="col-12">
      <input-text
        ref="descricao"
        v-model="form.descricao"
        label="FINANCEIRO.DESCRICAO"
        placeholder="FINANCEIRO.DESCRICAO"
        :maxLength="50"
        :minLength="6"
        required
      />
    </div>
    <div class="col-12">
      <input-select-search
        ref="tipoFluxoFinanceiro"
        v-model="form.tipoFluxoFinanceiro"
        label="FINANCEIRO.TIPO_FLUXO_FINANCEIRO"
        :options="opcoes.tipoFluxoFinanceiro"
        required
      />
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';

// Components:
import { InputText, InputSelectSearch } from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    InputText,
    InputSelectSearch,
  },
  mounted() {
    this.getTipoHistorico();
    this.getTipoFluxoFinanceiro();
    this.$emit('refs', this.$refs);
  },
  data() {
    return {
      opcoes: {
        tipoHistorico: [],
        tipoFluxoFinanceiro: [],
      },
    };
  },
  methods: {
    getTipoHistorico() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('historico-financeiro')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.tipoHistorico = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getTipoFluxoFinanceiro() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-fluxo-financeiro')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.tipoFluxoFinanceiro = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
