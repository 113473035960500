<template>
  <b-table
    :items="items"
    :fields="fields"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    responsive
    show-empty
    striped
    hover
  >
    <template #cell(acoes)="item">
      <b-dropdown variant="outline-default">
        <b-dropdown-item @click="editarHistorico(item.item)">
          {{ $t('GERAL.EDITAR') }}
        </b-dropdown-item>
        <b-dropdown-item @click="confirmarExclusao(item.item)">
          {{ $t('GERAL.EXCLUIR') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import HistoricoLancamentoService from '@/common/services/historico-lancamento/historico-lancamento.service.js';

export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'descricao',
          label: 'Documento',
          sortable: true,
        },
        {
          key: 'tipoFluxoFinanceiro',
          label: 'Tipo Fluxo Financeiro',
        },
        {
          key: 'acoes',
          label: 'Ações',
          sortable: true,
        },
      ],
    };
  },
  methods: {
    editarHistorico(item) {
      this.$emit('editar-historico', item);
    },
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        this.$t('GERAL.CONFIRMAR_EXCLUSAO'),
        this.$t('GERAL.CONFIRMAR_EXCLUSAO_ITEM').formatUnicorn({
          item: `${item.descricao}`,
        })
      );
      respostaExclusao.isConfirmed ? this.excluir(item.id) : {};
    },
    excluir(id) {
      this.$store.dispatch(START_LOADING);
      HistoricoLancamentoService.excluir(id)
        .then(() => {
          this.$emit('atualizar-tabela');
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('GERAL.ITEM_DELETADO').formatUnicorn({
              item: this.$t('FINANCEIRO.HISTORICO_LANCAMENTO'),
            })
          );
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
